import {observer} from "mobx-react-lite";
import {FormStepContainer} from "../components/decorations";
import {createContext, useContext, useMemo} from "react";
import styled, {useTheme} from "styled-components";
import {StyledProps, Theme} from "../../theme";
import {MladiVoznikStoreContext} from "./form_mladi_voznik";
import {MladiVoznikStore} from "../../stores/mladi_voznik_store";
import {CvvCategoryHeader} from "../components/headers";
import EditIcon from '../../assets/edit_icon.png';
import {DateFormatters} from "../../utils";
import {CvvRoutes, CvvStrings} from "../../strings";
import {billing, cvv_types, mladi_voznik} from "../../proto/compiled";
import {CvvRadio} from "../../components/radio";
import {CvvCollapsible} from "../../components/collapsible";
import {CvvInputField} from "../components/input";
import {CvvTextInput} from "../components/text_input";
import {MladiVoznikSummary} from "./form_mladi_voznik_components";
import {FormStoreContext} from "../../index";
import {FormStore} from "../../stores/form_store";
import {BankartStore} from "../../stores/bankart_store";
import {ReactComponent as NextSvg} from "../../assets/next.svg";
import {CvvButton} from "../../components/buttons";
import {LoadingOverlay} from "../../components/overlays";
import {SocketApiAckStatus, SocketApiTxStatus} from "proto_socket_typescript";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {FaCheck, FaWindowClose} from "react-icons/fa";
import PaymentType = billing.PaymentType;
import {ContentUnitFrame} from "../../components/dialogs";
import {BankartPayment} from "../components/bankart_payment";

const EditButton = styled.img.attrs({src: EditIcon})`
  height: 25px;
  margin-left: auto;
  cursor: pointer;
`;

export const SummaryCategory = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: -16px;
  margin-top: -8px;
`;

export const SummaryContainer = styled.div`
  display: flex;
  align-items: start;
  line-height: 1.5;
  gap: 4px;
`;

export const SummaryLabel = styled.span`
  ${({theme}: StyledProps) => theme.textTheme.body.with({color: 'black', weight: 'bold'}).css};
`;

export const SummaryValue = styled.span`
  ${({theme}: StyledProps) => theme.textTheme.body.with({color: 'black'}).css};
  flex-basis: 0;
  flex-grow: 1;
`;

export const SummaryList = styled.ol<{ numbered: boolean }>`
  display: inline-block;
  list-style: ${({numbered}) => numbered ? 'decimal' : 'none'};
  padding: 0 0 0 ${({numbered}) => numbered ? '16px' : '0'};
  margin: 0;
`;

export const SummaryListItem = styled.li`
  ${({theme}: StyledProps) => theme.textTheme.body.with({color: 'black'}).css};
`;


export const FormMladiVoznikS6 = observer((props: any) => {
    const formStore = useContext(FormStoreContext) as FormStore;
    const store = useContext(MladiVoznikStoreContext) as MladiVoznikStore;
    const forceCardPayment = formStore.cardPaymentDone || formStore.paymentInProgress;
    const paymentValid = formStore.cardPaymentDone || formStore?.paymentType !== billing.PaymentType.kreditna;
    const navigate = useNavigate();
    const theme = useTheme() as Theme;

    return <FormStepContainer style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    }}>
        <div style={{margin: '52px 0 16px', width: '100%'}}>
            <MladiVoznikSummary price={formStore.paymentAmount}
                                errors={formStore.orderSummary?.errors}/>
        </div>
        {!store.config.s1?.hide && <>
            <CvvCategoryHeader>
                Osebni podatki{!formStore.cardPaymentDone && <EditButton onClick={() => store.setStep(0)}/>}
            </CvvCategoryHeader>
            <SummaryCategory>
                {store.config.s1?.nameSurname && <SummaryContainer>
                    <SummaryLabel>Ime in priimek: </SummaryLabel>
                    <SummaryValue>{store.form.step1?.nameSurname}</SummaryValue>
                </SummaryContainer>}
                {store.config.s1?.address && <SummaryContainer style={{marginBottom: '32px'}}>
                    <SummaryLabel>Naslov: </SummaryLabel>
                    <SummaryValue>{store.form.step1?.address?.address}</SummaryValue>
                </SummaryContainer>}
                {store.config.s1?.email && <SummaryContainer>
                    <SummaryLabel>E-naslov: </SummaryLabel>
                    <SummaryValue>{store.form.step1?.email}</SummaryValue>
                </SummaryContainer>}
                {store.config.s1?.children && <SummaryContainer>
                    <SummaryLabel>Število otrok: </SummaryLabel>
                    <SummaryValue>{store.form.step1?.children}</SummaryValue>
                </SummaryContainer>}
                {store.config.s1?.mailingAddress && <SummaryContainer>
                    <SummaryLabel>Naslov za pošiljanje: </SummaryLabel>
                    <SummaryValue>{store.form.step1?.mailingAddress?.address}</SummaryValue>
                </SummaryContainer>}
                <SummaryValue>{store.step1?.result?.jeClan ? 'Sem član AMZS' : 'Nisem član AMZS'}</SummaryValue>
            </SummaryCategory>
        </>}
        {!store.config.s3?.hide && <>
            <CvvCategoryHeader style={{marginTop: '52px'}}>
                Drugi podatki{!formStore.cardPaymentDone && <EditButton onClick={() => store.setStep(2)}/>}
            </CvvCategoryHeader>
            <SummaryCategory>
                {store.config.s3?.emso && <SummaryContainer>
                    <SummaryLabel>EMŠO: </SummaryLabel>
                    <SummaryValue>{store.form.step3?.emso}</SummaryValue>
                </SummaryContainer>}
                {store.config.s3?.stVozniske && <SummaryContainer>
                    <SummaryLabel>Št. vozniške: </SummaryLabel>
                    <SummaryValue>{store.form.step3?.stVozniske}</SummaryValue>
                </SummaryContainer>}
                {store.config.s3?.krajRojstva && <SummaryContainer>
                    <SummaryLabel>Kraj rojstva: </SummaryLabel>
                    <SummaryValue>{store.form.step3?.krajRojstva}</SummaryValue>
                </SummaryContainer>}
                {store.config.s3?.veljavnoVoznisko && <SummaryContainer>
                    <SummaryLabel>Imam veljavno vozniško dovoljenje: </SummaryLabel>
                    <SummaryValue>{store.form.step3?.veljavnoVoznisko ? 'DA' : 'NE'}</SummaryValue>
                </SummaryContainer>}
                {store.config.s3?.veljavnoVozniskoKazenskeTocke && <>
                    <SummaryContainer>
                        <SummaryLabel>Napotilo: </SummaryLabel>
                        <SummaryValue>{CvvStrings.napotilo[store.form.step3?.napotilo ?? 0]}</SummaryValue>
                    </SummaryContainer>
                    {store.form.step3?.napotilo === mladi_voznik.KazenskeTockeNapotilo.sodisce && <>
                        <SummaryContainer>
                            <SummaryLabel>Pristojno sodišče: </SummaryLabel>
                            <SummaryValue>{store.form.step3?.napotiloPristojnoSodisce}</SummaryValue>
                        </SummaryContainer>
                        <SummaryContainer>
                            <SummaryLabel>Številka napotila: </SummaryLabel>
                            <SummaryValue>{store.form.step3?.napotiloStevilka}</SummaryValue>
                        </SummaryContainer>
                    </>}
                    {(store.form.step3?.napotilo === mladi_voznik.KazenskeTockeNapotilo.sodisce || store.form.step3?.napotilo === mladi_voznik.KazenskeTockeNapotilo.zdravstveni_pregled) &&
                        <SummaryContainer>
                            <SummaryLabel>Datum napotila: </SummaryLabel>
                            <SummaryValue>{store.form.step3?.napotiloDatum?.ts && DateFormatters.date(new Date(store.form.step3!.napotiloDatum!.ts as number))}</SummaryValue>
                        </SummaryContainer>}

                </>}
                {store.config.s1?.isStudent && <SummaryContainer>
                    <SummaryLabel>Sem študent / upokojenec: </SummaryLabel>
                    <SummaryValue>{store.form.step1?.isStudent ? 'DA' : 'NE'}</SummaryValue>
                </SummaryContainer>}
                {store.config.s3?.datumIzdajeVozniske && <SummaryContainer>
                    <SummaryLabel>Datum izdaje vozniške: </SummaryLabel>
                    <SummaryValue>{store.form.step3?.datumIzdajeVozniske?.ts && DateFormatters.date(new Date(store.form.step3!.datumIzdajeVozniske!.ts as number))}</SummaryValue>
                </SummaryContainer>}
                {store.config.s3?.datumOpravljenegaIntenzivnegaTecaja && <SummaryContainer>
                    <SummaryLabel>Datum opravljenega intenzivnega tečaja: </SummaryLabel>
                    <SummaryValue>{store.form.step3?.datumOpravljenegaIntenzivnegaTecaja?.ts && DateFormatters.date(new Date(store.form.step3!.datumOpravljenegaIntenzivnegaTecaja!.ts as number))}</SummaryValue>
                </SummaryContainer>}
            </SummaryCategory>
        </>}
        {!store.config.s4?.hide && <>
            <CvvCategoryHeader style={{marginTop: '52px'}}>
                Dodatno{!formStore.cardPaymentDone && <EditButton onClick={() => store.setStep(3)}/>}
            </CvvCategoryHeader>
            <SummaryCategory>
                {store.config.s4?.najemVozila && <SummaryContainer>
                    <SummaryLabel>Vozilo: </SummaryLabel>
                    <SummaryValue>{CvvStrings.vozilo[store.form!.step4!.vozilo as number]}</SummaryValue>
                </SummaryContainer>}
                {store.config.s4?.kosilo && <SummaryContainer>
                    <SummaryLabel>Kosilo: </SummaryLabel>
                    <SummaryValue>{store.step1?.result?.jeClan ? 'BREZPLAČNO' : store.form.step4?.kosilo ? 'DA' : 'NE'}</SummaryValue>
                </SummaryContainer>}
                {(store.form.step4?.vSkupiniSPrijateljem || store.form!.step4!.vozilo === cvv_types.Vozilo.najeto_prijatelj) &&
                    <SummaryContainer>
                        <SummaryLabel>Prijatelj: </SummaryLabel>
                        <SummaryValue>{store.form!.step4!.vozilo === cvv_types.Vozilo.najeto_prijatelj ? store.form.step4?.voziloPrijateljEmail : store.form.step4?.skupinaPrijateljIme}</SummaryValue>
                    </SummaryContainer>}
                {store.form.step4!.opomba && <SummaryContainer>
                    <SummaryLabel>Opomba: </SummaryLabel>
                    <SummaryValue>{store.form.step4!.opomba}</SummaryValue>
                </SummaryContainer>}
            </SummaryCategory>
        </>}

        <CvvCategoryHeader style={{marginTop: '52px'}}>
            Plačilo
        </CvvCategoryHeader>
        <LoadingOverlay style={{width: '100%'}} builder={(action) => <>
            {!formStore.fullyPaid &&
                 <>
                {!formStore.api.authenticated && formStore.meta?.paymentTypes.includes(PaymentType.kreditna) && <><CvvRadio name={'placilo'}
                                                                                            id={'kreditna'}
                                                                                            checked={formStore?.paymentType === billing.PaymentType.kreditna || forceCardPayment}
                                                                                            onChange={() => {
                                                                                                formStore.paymentType = billing.PaymentType.kreditna;
                                                                                            }}
                                                                                            label={'SPLETNO PLAČILO'}/>
                    <div style={{
                        marginTop: formStore?.paymentType === billing.PaymentType.kreditna ? '-8px' : '0',
                        marginBottom: formStore?.paymentType === billing.PaymentType.kreditna ? '16px' : '0',
                        width: "100%",
                        transition: 'all 300ms',
                        overflow: 'hidden'
                    }}>
                        <CvvCollapsible expanded={formStore?.paymentType === billing.PaymentType.kreditna}>
                            <ContentUnitFrame style={{padding: '16px'}}>
                                <BankartPayment
                                    mladiVoznikStore={store}
                                    formStore={formStore}
                                    inProgress={(p) => {
                                        formStore.paymentInProgress = p;
                                        formStore.paymentInitiated = p;
                                    }}
                                    onPaymentErrorRetry={() => {
                                        formStore.cardPaymentError = false;
                                    }}
                                />
                            </ContentUnitFrame>
                        </CvvCollapsible>
                    </div>
                </>}
                {formStore.meta?.paymentTypes.includes(PaymentType.darilni_bon) && <><CvvRadio name={'placilo'} id={'darilni-bon'}
                          checked={formStore?.paymentType === billing.PaymentType.darilni_bon}
                          disabled={forceCardPayment}
                          onChange={() => {
                              formStore.paymentType = billing.PaymentType.darilni_bon;
                          }}
                          label={'DARILNI BON'}/>
                <div style={{
                    marginTop: formStore?.paymentType === billing.PaymentType.darilni_bon ? '-8px' : '0',
                    marginBottom: formStore?.paymentType === billing.PaymentType.darilni_bon ? '16px' : '0',
                    width: "100%",
                    transition: 'all 300ms',
                    overflow: 'hidden'
                }}>
                    <CvvCollapsible expanded={formStore?.paymentType === billing.PaymentType.darilni_bon}>
                        <CvvInputField error={store.validators.step6.stDarilnegaBona()}
                                       label={'ŠTEVILKA DARILNEGA BONA'}
                                       input={
                                           <CvvTextInput invalid={!!store.validators.step6.stDarilnegaBona()}
                                                         value={store.step6.stDarilnegaBona!}
                                                         type={'text'}
                                                         onChange={(e) => {
                                                             if (e.target.value.startsWith('CVV')) {
                                                                 store.step6.stDarilnegaBona = e.target.value;
                                                             }
                                                         }}/>
                                       }/>
                    </CvvCollapsible>
                </div></>}
                {formStore.meta?.paymentTypes.includes(PaymentType.gotovina) && <><CvvRadio name={'placilo'} id={'gotovina'}
                          checked={formStore?.paymentType === billing.PaymentType.gotovina}
                          disabled={forceCardPayment}
                          onChange={() => {
                              formStore.paymentType = billing.PaymentType.gotovina;
                          }}
                          label={'PLAČILO NA CENTRU VARNE VOŽNJE'}/>
                </>}
            </>}
            {formStore.fullyPaid && <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
            }}>
                {(formStore.paymentAmount ?? 0) > 0 && <theme.textTheme.headline4.span>
                    <FaCheck
                        style={{fontSize: '20px', marginRight: '12px', marginBottom: '-2px'}}/>
                    Tečaj je že plačan.
                </theme.textTheme.headline4.span>}
                {formStore.paymentAmount === 0 && <theme.textTheme.headline4.span>
                    <FaWindowClose
                        style={{fontSize: '20px', marginRight: '12px', marginBottom: '-2px'}}/>
                    Tečaj nima določenega cenika!
                </theme.textTheme.headline4.span>}

            </div>}
            {(store.api.authenticated || (formStore.paymentAmount ?? 0) > 0 || formStore.cardPaymentDone) && <CvvButton
                onClick={() => action(store.step6.submit()).then((r: SocketApiTxStatus) => {
                    if (r.status === SocketApiAckStatus.success || r.error === cvv_types.CvvErrorCode.already_submitted) {
                        store.done = true;
                        if (store.api.authenticated) {
                            navigate(CvvRoutes.admin.prijavljeneOsebe, {replace: true});
                        } else {
                            window.onbeforeunload = null;
                            window.location.href = 'https://www.amzs.si/cvv/prijava-na-tecaj/zahvalna-stran';
                        }
                    } else {
                        toast.error(r.errorMessage ?? 'Neznana napaka');
                    }
                })}
                style={{marginTop: '16px'}}
                disabled={!formStore.fullyPaid && !paymentValid}>
                ZAKLJUČI<NextSvg height={'22px'} style={{marginLeft: 'auto'}}/>
            </CvvButton>}
        </>}/>

    </FormStepContainer>
})
