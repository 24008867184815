import styled from "styled-components";
import {CvvTextInput} from "./text_input";
import React, {RefObject, useState} from "react";
import {CvvTheme} from "../../theme";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;


interface CvvPinInputProps {
    invalid: boolean;
    digits: number;
    onChange: (val?: string) => any;
    onSubmit?: () => any;
}

export const CvvPinInput = ({invalid, digits, onChange, onSubmit}: CvvPinInputProps) => {
    const children = [];
    const [pin, setPin] = useState(new Array(digits).fill(NaN));
    const [refs] = useState<RefObject<any>[]>((() => {
        const refs = [];
        for (let i = 0; i < digits; i++) {
            refs.push(React.createRef());
        }
        return refs;
    })());
    for (let i = 0; i < digits; i++) {
        children.push(<CvvTextInput
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    e.preventDefault();
                    e.stopPropagation();
                    if (onSubmit) {
                        onSubmit();
                    }
                }
            }}
            type={'number'}
            pattern={'[0-9]*'}
            key={'pin-' + i}
            invalid={invalid}
            ref={refs[i]}
            value={isNaN(pin[i]) ? '' : pin[i]}
            style={{
                width: '40px',
                margin: '0 8px 0 8px',
                textAlign: 'center',
                border: '1px solid ' + (invalid ? CvvTheme.error : CvvTheme.idle),
                borderRadius: '8px',
                background: CvvTheme.shade,
            }}
            onChange={(e) => {
                let newPin = Array.from(pin);
                const len = e.target.value.length;
                if (len === digits && /^[0-9]+$/.test(e.target.value)) {
                    newPin = e.target.value.split('').map((c) => parseInt(c));
                } else if (len <= 1) {
                    const atEnd = i === digits - 1;

                    newPin[i] = parseInt(e.target.value);
                    if (!isNaN(newPin[i]) && !atEnd) {
                        refs[i + 1].current.focus();
                    }
                }

                setPin(newPin);
                for (const d of newPin) {
                    if (isNaN(d)) {
                        onChange();
                        return;
                    }
                }
                onChange(newPin.join(''));
            }}

            onKeyUp={(e) => {
                const atEnd = i === digits - 1;
                const atStart = i === 0;

                if (e.key === 'ArrowLeft' && !atStart) {
                    e.preventDefault();
                    e.stopPropagation();
                    refs[i - 1].current.focus();
                    return;
                } else if (e.key === 'ArrowRight' && !atEnd) {
                    e.preventDefault();
                    e.stopPropagation();
                    refs[i + 1].current.focus();
                    return;
                } else if (e.key === 'Backspace' && isNaN(pin[i])) {
                    e.preventDefault();
                    e.stopPropagation();
                    if (isNaN(pin[i]) && !atStart) {
                        refs[i - 1].current.focus();
                    }
                }
            }}
        />);
    }
    return <Container>
        {children}
    </Container>
}